'use client';
import React from 'react';
import { Text, Box, Link, Button } from '@mentimeter/ragnar-ui';
import { ErrorLayout } from '../ui';

const FiveHundred = () => {
  const mailToData = [
    'mailto:hello@mentimeter.com',
    '?subject=Server error on voting web',
  ];

  return (
    <ErrorLayout>
      <Box alignItems="center">
        <Text
          fontFamily="heading"
          lineHeight="heading"
          fontWeight="semiBold"
          color="text"
          fontSize={5}
          as="h1"
          textAlign="center"
        >
          We are sorry for the inconvenience
        </Text>
        <Text my={2}>
          There was a small issue with our system. Please select the button
          below to <Text fontWeight="semiBold">try again</Text>.
        </Text>
        <Box alignItems="center">
          <Button
            size="large"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </Box>
        <Text my={2}>
          If the problem persists, please contact us at{' '}
          <Link href={mailToData.join('')}>hello@mentimeter.com</Link>
        </Text>
        <Text as="p" fontStyle="italic" textAlign="center">
          /The Mentimeter Team
        </Text>
      </Box>
    </ErrorLayout>
  );
};

export default FiveHundred;
